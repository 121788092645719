<script lang="ts" setup>
import { usePlayerStore } from '~/stores/usePlayerStore'

const playerStore = usePlayerStore()

const error = ref(false)

const onError = () => {
  error.value = true
}

const rounded = computed(() => {
  return playerStore.isStation ? 'rounded-full' : 'rounded-xl'
})
</script>

<template>
  <div
    v-if="playerStore.media"
    :class="[rounded]"
    class="md:size-16 size-12 border border-zeno-gray-border-light bg-zeno-gray-light"
  >
    <NuxtImg
      v-if="!error"
      :key="playerStore.media.id"
      :alt="playerStore.media?.title || playerStore.media.subTitle"
      :class="[rounded]"
      :src="playerStore.media.logo"
      @error="onError"
    />
  </div>
</template>

<style scoped></style>
